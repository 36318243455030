const faqItems = document.querySelectorAll('.faq__item');
const faqButtons = document.querySelectorAll('.faq__button');

if (faqItems && faqButtons) {
  const switchFaqItem = (e) => {
    let activeButtonNumber = e.currentTarget.getAttribute('data-faq-button')

    faqItems.forEach((faqItem, index) => {
      let faqItemNumber = faqItem.getAttribute('data-faq-item')
      if (activeButtonNumber === faqItemNumber) {
        faqItem.classList.toggle("faq__item--active");
      }
    })
  }

  faqButtons.forEach(button => {
      button.addEventListener('click', switchFaqItem);
    }
  )
}

