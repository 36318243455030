const servicesTabsButtons = document.querySelectorAll('.services__button');
const servicesTabs = document.querySelectorAll('.services__tab');

if (servicesTabsButtons && servicesTabs) {
  const switchTab = function (e) {
    let activeButtonNumber = e.currentTarget.getAttribute('data-button')

    servicesTabs.forEach((tab, index) => {
      let tabNumber = tab.getAttribute('data-tab')
      if (activeButtonNumber === tabNumber) {
        servicesTabsButtons[index].classList.add("button--active");
        tab.classList.add("active");
      } else {
        servicesTabsButtons[index].classList.remove("button--active");
        tab.classList.remove("active");
      }
    })
  }

  servicesTabsButtons.forEach(tabButton => {
    tabButton.addEventListener('click', switchTab);
  })
}


const servicesItemButtons = document.querySelectorAll('.services__tab-button');
const servicesItems = document.querySelectorAll('.services__tab-item');

if (servicesItemButtons && servicesItems) {
  const switchItem = function (e) {
    let activeItemButtonNumber = e.currentTarget.getAttribute('data-button-item')

    servicesItems.forEach((item, index) => {
      let itemNumber = item.getAttribute('data-tab-item')
      if (activeItemButtonNumber === itemNumber) {
        servicesItemButtons[index].classList.toggle("active");
        item.classList.toggle("active");
      } else {
        servicesItemButtons[index].classList.remove("active");
        item.classList.remove("active");
      }
    })
  }

  servicesItemButtons.forEach(tabButton => {
    tabButton.addEventListener('click', switchItem);
  })
}
