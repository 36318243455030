new Swiper('.swiper-container-team', {
  slidesPerView: 1,
  spaceBetween: 0,

  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: ".team__swiper-pagination",
        clickable: "true",
      },
    }
  },
  navigation: {
    nextEl: '.team__button--next',
    prevEl: '.team__button--prev',
  },
});