document.addEventListener("DOMContentLoaded", function () {
  function showStatusModal(modalId) {
    var int, timer;
    var modalTimer = document.querySelector(".success-modal__text span");

    document
      .querySelectorAll(".modal.is-open")
      .forEach((e) => MicroModal.close(e.getAttribute("id")));

    MicroModal.show(modalId, {
      onClose: () => {
        modalTimer.textContent = 5;

        clearInterval(int);
        clearTimeout(timer);
      },
    });

    int = setInterval(function () {
      modalTimer.textContent -= 1;
    }, 1000);

    timer = setTimeout(function () {
      MicroModal.close(modalId);
    }, 5000);
  }

  var $form1 = $(".c-form--callback");
  var $cForm1 = new cForm({
    cForm: $form1, // объект формы, обязательно
    id: "callback", // индетификатор формы, обязательно
    onSuccess: function() {
      showStatusModal("success-modal");
      fbq('track', 'Lead');
      ym(86383658,'reachGoal','forma2');
    },
    onError: function() {
      showStatusModal("error-modal");
    },
  });
  $cForm1.run();

  var $form2 = $(".c-form--modal-callback");
  var $cForm2 = new cForm({
    cForm: $form2, // объект формы, обязательно
    id: "modal-callback", // индетификатор формы, обязательно
    onSuccess: function() {
      showStatusModal("success-modal");
      fbq('track', 'Lead');
      ym(86383658,'reachGoal','forma1');
    },
    onError: function() {
      showStatusModal("error-modal");
    },
  });
  $cForm2.run();

  var $form3 = $(".c-form--modal-callback-price");
  var $cForm3 = new cForm({
    cForm: $form3, // объект формы, обязательно
    id: "modal-callback-price", // индетификатор формы, обязательно
    onSuccess: function() {
      showStatusModal("success-modal");
      fbq('track', 'Lead');
      ym(86383658,'reachGoal','forma1');
    },
    onError: function() {
      showStatusModal("error-modal");
    },
  });
  $cForm3.run();

  var $form4 = $(".c-form--modal-callback-sign");
  var $cForm4 = new cForm({
    cForm: $form4, // объект формы, обязательно
    id: "modal-callback-sign", // индетификатор формы, обязательно
    onSuccess: function() {
      showStatusModal("success-modal");
      fbq('track', 'Lead');
      ym(86383658,'reachGoal','forma1');
    },
    onError: function() {
      showStatusModal("error-modal");
    },
  });
  $cForm4.run();
});
