const header = document.querySelector('.header')
const burgerButton = document.querySelector('.header__burger-button');
const closeBurgerButton = document.querySelector('.header__burger-button--close');
const nav = document.querySelector('.header__nav');
const navLinks = document.querySelectorAll('.header__nav-link')


if (burgerButton && nav && header && closeBurgerButton && navLinks.length > 0) {
  const switchNav = () => {
    nav.classList.toggle('header__nav--active');
    header.classList.toggle('header--active')
  }

  burgerButton.addEventListener('click', switchNav);
  closeBurgerButton.addEventListener('click', switchNav);

  navLinks.forEach(navLink => {
    navLink.addEventListener('click', function () {
      nav.classList.remove('header__nav--active');
      header.classList.remove('header--active');
    })
  })
}
