new Swiper('.swiper-clients', {
  direction: 'horizontal',
  slidesPerView: 1,
  spaceBetween: 82,
  autoHeight: true,

  navigation: {
    nextEl: '.clients__button--next',
    prevEl: '.clients__button--prev',
  },
});
