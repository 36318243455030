new Swiper('.swiper-diagnosis', {
  slidesPerView: 1,
  spaceBetween: 63,
  navigation: {
    nextEl: '.diagnosis__swiper-button--next',
    prevEl: '.diagnosis__swiper-button--prev',
  },
  pagination: {
    el: ".diagnosis__swiper-pagination",
  },
});