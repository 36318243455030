document.addEventListener('DOMContentLoaded', function() {
  const introBullet = document.querySelector('.sections-pagination__bullet--intro');
  const servicesBullet = document.querySelector('.sections-pagination__bullet--services');
  const diagnosisBullet = document.querySelector('.sections-pagination__bullet--diagnosis');
  const pricesBullet = document.querySelector('.sections-pagination__bullet--prices');
  const videoplayerBullet= document.querySelector('.sections-pagination__bullet--videoplayer');
  const formBullet= document.querySelector('.sections-pagination__bullet--form');
  const clientsBullet= document.querySelector('.sections-pagination__bullet--clients');
  const teamBullet = document.querySelector('.sections-pagination__bullet--team');
  const faqBullet = document.querySelector('.sections-pagination__bullet--faq');
  const contactsBullet = document.querySelector('.sections-pagination__bullet--contacts');
  const bullets = document.querySelectorAll('.sections-pagination__bullet')

  const inViewSection = (sectionSelector, bullet) => {
    inView(sectionSelector)
      .on('enter', function () {
        bullets.forEach(el => el.classList.remove('sections-pagination__bullet--active'))
        bullet.classList.add('sections-pagination__bullet--active');
      })
      .on('exit', function () {
        bullet.classList.remove('sections-pagination__bullet--active');
      });
  };

  inViewSection('.intro', introBullet);
  inViewSection('.services', servicesBullet);
  inViewSection('.diagnosis', diagnosisBullet);
  inViewSection('.prices', pricesBullet);
  inViewSection('.videoplayer', videoplayerBullet);
  inViewSection('.form', formBullet);
  inViewSection('.clients', clientsBullet);
  inViewSection('.team', teamBullet);
  inViewSection('.faq', faqBullet);
  inViewSection('.contacts', contactsBullet);

});